<template>
    <v-container class="down-top-padding" fluid>
        <h3>Журнал инструктажей</h3>
        <v-data-table
            :headers="headers"
            :items="users"
            item-key="id"
            class="elevation-1"
            :search="search"
            no-data-text="Результатов нет"
                no-results-text="Результатов нет"
                :footer-props="{'items-per-page-text':'Количество',
                'items-per-page-options':[5,10,20]
                }"
            >
            <template v-slot:footer.page-text="{ pageStart,pageStop,itemsLength }">
               {{ pageStart}}-{{pageStop}} из {{itemsLength}}
            </template>
            <template v-slot:item.date="{ item }">
                {{ parseInt(item.date) | moment("D MMMM YYYY  HH:mm") }}
            </template>
            <template v-slot:item.user="{ item }">
                {{item.user.last_name}} {{item.user.name}} {{item.user.second_name}} 
            </template>
            <template v-slot:item.position="{ item }">
                {{item.user.position}}
            </template>
            <template v-slot:item.lesson="{ item }">
                {{item.lesson.topic.name}} - {{item.lesson.name}}
            </template>
        </v-data-table>
    </v-container>
</template>
<script>

    export default {
        name: "index",
        data: () => ({
                headers: [
                    {text: 'Дата', value: 'date'},
                    {text: 'ФИО', value: 'user'},
                    {text: 'Должность', value: 'position'},
                    {text: 'Занятие', value: 'lesson'}
                ],
                search: '',
                users: []
            }),
        props: {
            groupCourseId: Number,
        },
        components: {

        },
        methods: {
            fetchData() {
                this.$http.get('progress/course-instructions?id=' + this.groupCourseId).then(response => {
                    this.users = response.data
                })
            }
        },
        mounted() {
            this.fetchData()
        }
    };
</script>