var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('h3',[_vm._v(_vm._s(_vm.timetable.lesson.topic.name)+" - "+_vm._s(_vm.timetable.lesson.name)+" ("+_vm._s(_vm._f("moment")(parseInt(_vm.event.startTime),"D MMMM YYYY HH:mm"))+")")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"item-key":"id","search":_vm.search},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [(!item.avatar)?_c('v-icon',{staticClass:"blue-grey",attrs:{"large":"","dark":""}},[_vm._v("mdi-account")]):_c('v-avatar',{attrs:{"x-large":""}},[_c('img',{attrs:{"src":item.avatar}})])]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_name)+" "+_vm._s(item.second_name)+" "+_vm._s(item.name)+" ")]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [(item.progress[0])?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(parseInt(item.progress[0].startTime)>parseInt(_vm.event.startTime)+86400)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Пользователь открыл занятие позже даты его проведения")])]),_vm._v(" "+_vm._s(_vm._f("moment")(parseInt(item.progress[0].startTime),"D MMMM YYYY HH:mm"))+" ")],1):_c('span',[_vm._v(" - ")])]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [(item.progress[0] && item.progress[0].endTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(parseInt(item.progress[0].endTime),"D MMMM YYYY HH:mm"))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [(item.progress[0])?_c('span',[_vm._v(" "+_vm._s(item.progress[0].time)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.instruction",fn:function(ref){
var item = ref.item;
return [(_vm.timetable.lesson.instruction)?_c('span',[(_vm.event.instructions.find(function (el){ return el.userId==item.user_id; }))?_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-book-alert-outline")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(parseInt(_vm.event.instructions.find(function (el){ return el.userId==item.user_id; }).date),"D MMMM YYYY HH:mm"))+" ")],1):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-book-alert-outline")])],1):_vm._e()]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }