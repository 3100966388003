<template>
    <div>
        <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="newLesson.lessonDate"
                    label="Дата занятия"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
            </template>
            <v-date-picker
                locale="ru-RU"
                v-model="newLesson.lessonDate"
                @input="dateMenu = false"
                ></v-date-picker>
        </v-menu>
        <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="newLesson.startTime"
                    label="Время начала занятия"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>

            </template>
            <v-time-picker
                v-model="newLesson.startTime"
                class="mt-4"
                format="24hr"
                scrollable

                ></v-time-picker>
        </v-menu>
        <v-menu
            v-model="endMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="newLesson.endTime"
                    label="Время завершения занятия"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>

            </template>
            <v-time-picker
                v-model="newLesson.endTime"
                class="mt-4"
                format="24hr"
                scrollable

                ></v-time-picker>
        </v-menu>
<v-select single-line
          :items="[{text:'Обычное занятие',value:null},{text:'ПТЗ',value:'PTZ'},{text:'ПТУ',value:'PTU'}]"
          label="Тип занятия"
          v-model='newLesson.type'
        ></v-select>
    <v-autocomplete v-if='newLesson.type==null'
        :loading="lessonsLoading" 
        :items="lessons" 
        v-model="newLesson.lessonId" 
        :search-input.sync="search"
        item-value="id" 
        label="Поиск..."
        item-text="name"/>
        <v-autocomplete v-if='newLesson.type!=null'
        :loading="objectsLoading" 
        :items="objects" 
        v-model="newLesson.object" 
        :search-input.sync="search"
        item-value="id" 
        label="Поиск..."
        item-text="name">
            <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    <img :src="getObjectIcon(data.item.type)">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle> {{data.item.address}} ({{data.item.station.name}})</v-list-item-subtitle>
                    
                  </v-list-item-content>
                </template>
              </template>
        </v-autocomplete>
    <wysiwyg v-if='newLesson.type!=null' v-model="newLesson.task" />
    <v-btn color="danger" @click="$emit('close')">Отменить</v-btn>
    <v-btn color="success" @click="save()">Сохранить</v-btn>
    </div>
    
</template>

<script>

    export default {
        name: "index",
        props: {
            lesson: Object,
            isNewRecord: Boolean,
            groupCourseId:String
        },
        data: () => ({
                newLesson:{
                    lessonDate: null,
                    startTime: null,
                    endTime: null,
                    lessonId:null,
                    type:null,
                    task:'',
                    id:null,
                    object:null
                },
                dateMenu: false,
                startMenu: false,
                endMenu: false,
                lessonsLoading:true,
                lessons:[],
                objectsLoading:true,
                objects:[],
            }),
        watch: {
            lesson(){
                this.newLesson=JSON.parse( JSON.stringify( this.lesson ) );
                this.newLesson.lessonDate=this.$moment.unix(parseInt(this.newLesson.startTime)).format('YYYY-MM-DD');
                this.newLesson.startTime=this.$moment.unix(parseInt(this.newLesson.startTime)).format("HH:mm");
                this.newLesson.endTime=this.$moment.unix(parseInt(this.newLesson.endTime)).format("HH:mm");
            }
        },
        methods: {
            getObjectIcon(type) {
            switch (type) {
            case 'POO':
                    return '/map/poo.png'
                    case 'KVO':
                    return '/map/kvo.png'
                    case 'SIO':
                    return '/map/social.png'
                    case 'CIO':
                    return '/map/culture.png'
                    case 'NSO':
                    return '/map/night.png'
                    case 'MPL':
                    return '/map/mass.png'
            }
            },
             time(){
return parseInt(new Date().getTime()/1000)
},
            save(){
                var data=JSON.parse( JSON.stringify( this.newLesson ) );
                var startTime=data.startTime.split(":");
                startTime=startTime[0]*60*60+startTime[1]*60
                var endTime=data.endTime.split(":");
                endTime=endTime[0]*60*60+endTime[1]*60
                data.startTime=startTime+this.$moment(data.lessonDate, 'YYYY-MM-DD').unix()
                data.endTime=endTime+this.$moment(data.lessonDate, 'YYYY-MM-DD').unix()
                if(!this.newLesson.id){
                this.$http.post('calendar/save?groupCourseId='+this.groupCourseId,
                   data).then((response) => {
                       
                    this.$emit('elementAdd',response.data)
                })
                }else{
                   this.$http.post('calendar/save?groupCourseId='+this.groupCourseId+'&id=' + this.newLesson.id,
                   data).then((response) => {
                      
                    this.$emit('elementAdd',response.data)
                })             
                }
            },
            fetchLessons(){
                this.$http.get('calendar/lessons-list?groupCourseId=' + this.groupCourseId).then(response => {
                    this.lessons = response.data
                    this.lessonsLoading=false
                })
            },
                        fetchObjects(){
                this.$http.get('calendar/objects-list').then(response => {
                    this.objects = response.data
                    this.objectsLoading=false
                })
            }
        },
        mounted() {
            this.fetchLessons()
            this.fetchObjects()
        },
        components: {

        }
    };
</script>