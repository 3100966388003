var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('h3',[_vm._v("Успеваемость")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"item-key":"id","search":_vm.search,"no-data-text":"Результатов нет","no-results-text":"Результатов нет","footer-props":{'items-per-page-text':'Количество',
            'items-per-page-options':[5,10,20]
            }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
            var pageStart = ref.pageStart;
            var pageStop = ref.pageStop;
            var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" из "+_vm._s(itemsLength)+" ")]}},{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [(!item.avatar)?_c('v-icon',{staticClass:"blue-grey",attrs:{"large":"","dark":""}},[_vm._v("mdi-account")]):_c('v-avatar',{attrs:{"x-large":""}},[_c('img',{attrs:{"src":item.avatar}})])]}},{key:"item.last_name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_name)+" "+_vm._s(item.second_name)+" "+_vm._s(item.name)+" ")]}},{key:"item.count",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){_vm.userId=item.user_id;_vm.statModal=true}}},[_vm._v(_vm._s(item.progress.length)+"/"+_vm._s(_vm.lessonsCount))])]}},{key:"item.rating",fn:function(ref){
            var item = ref.item;
return [_c('v-rating',{attrs:{"readonly":"","value":item.rating,"background-color":"purple lighten-3","color":"purple","dense":"","half-increments":"","size":"16"}})]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"1024"},model:{value:(_vm.statModal),callback:function ($$v) {_vm.statModal=$$v},expression:"statModal"}},[_c('v-card',{attrs:{"scrollable":""}},[_c('v-card-text',[(_vm.userId)?_c('uprogress',{ref:"uprogress",attrs:{"groupCourseId":_vm.groupCourseId,"userId":_vm.userId}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }