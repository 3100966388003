<template>
    <v-container class="down-top-padding" fluid>
        <h3>{{timetable.lesson.topic.name}} - {{timetable.lesson.name}} ({{ parseInt(event.startTime) | moment("D MMMM YYYY  HH:mm") }})</h3>
            <v-data-table
                :headers="headers"
                :items="users"
                item-key="id"
                class="elevation-1"
                :search="search"
                >
                <template v-slot:item.avatar="{ item }">
                    <v-icon  large v-if="!item.avatar"
                                     class="blue-grey"
                                     dark>mdi-account</v-icon>
                            <v-avatar x-large v-else ><img :src="item.avatar"/></v-avatar>
                </template>
                <template v-slot:item.last_name="{ item }">
                    {{item.last_name}} {{item.second_name}} {{item.name}} 
                </template>
                <template v-slot:item.start="{ item }">
                    <span v-if="item.progress[0]">
                        <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="red" v-if="parseInt(item.progress[0].startTime)>parseInt(event.startTime)+86400">mdi-alert</v-icon>
      </template>
      <span>Пользователь открыл занятие позже даты его проведения</span>
    </v-tooltip>
                        
                        {{ parseInt(item.progress[0].startTime) | moment("D MMMM YYYY  HH:mm") }}
                    </span>
                    <span v-else>
                        -
                    </span>
                </template>
                <template v-slot:item.end="{ item }">
                    <span v-if="item.progress[0] && item.progress[0].endTime">
                        {{ parseInt(item.progress[0].endTime) | moment("D MMMM YYYY  HH:mm") }} 
                    </span>
                    <span v-else>
                        -
                    </span>
                </template>
                <template v-slot:item.time="{ item }">
                    <span v-if="item.progress[0]">
                        {{item.progress[0].time}} 
                    </span>
                    <span v-else>
                        -
                    </span>
                </template>
                <template v-slot:item.instruction="{ item }">
                    <span v-if="timetable.lesson.instruction">
                        <span v-if="event.instructions.find(el=>el.userId==item.user_id)" ><v-icon color="green">mdi-book-alert-outline</v-icon><br>
                        {{ parseInt(event.instructions.find(el=>el.userId==item.user_id).date) | moment("D MMMM YYYY  HH:mm") }} </span>
                        <v-icon v-else color="red">mdi-book-alert-outline</v-icon>
                    </span>
                </template>
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        class="mx-4"
                        ></v-text-field>
                </template>

            </v-data-table>

    </v-container>
</template>
<script>

    export default {
        name: "index",
        data: () => ({
                headers: [
                    {text: 'Аватар', value: 'avatar'},
                    {text: 'ФИО', value: 'last_name'},
                    {text: 'Дата начала', value: 'start'},
                    {text: 'Дата завершения', value: 'end'},
                    {text: 'Затраченное время, с.', value: 'time'},
                    {text: 'Инструктаж', value: 'instruction'},
                ],
                search: '',
                users: [],
                event: {}
            }),
        props: {
            timetable: Object,
        },
        components: {

        },
        watch: {
            timetable() {
                this.fetchData()
            }
        },
        methods: {
            fetchData() {
                this.$http.get('progress/lesson-progress?id=' + this.timetable.id).then(response => {
                    this.users = response.data.users
                    this.event = response.data.event
                })
            }
        },
        mounted() {
            this.fetchData()
        }
    };
</script>