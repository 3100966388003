<template>
    <v-container class="down-top-padding" fluid>
        <h3>Успеваемость</h3>
        <v-data-table
            :headers="headers"
            :items="users"
            item-key="id"
            class="elevation-1"
            :search="search"
                        no-data-text="Результатов нет"
                no-results-text="Результатов нет"
                :footer-props="{'items-per-page-text':'Количество',
                'items-per-page-options':[5,10,20]
                }"
            >
                        <template v-slot:footer.page-text="{ pageStart,pageStop,itemsLength }">
               {{ pageStart}}-{{pageStop}} из {{itemsLength}}
            </template>
            <template v-slot:item.avatar="{ item }">
                <v-icon  large v-if="!item.avatar"
                         class="blue-grey"
                         dark>mdi-account</v-icon>
                <v-avatar x-large v-else ><img :src="item.avatar"/></v-avatar>
            </template>
            <template v-slot:item.last_name="{ item }">
                {{item.last_name}} {{item.second_name}} {{item.name}}
            </template>
            <template v-slot:item.count="{ item }">
                <v-btn  @click="userId=item.user_id;statModal=true">{{item.progress.length}}/{{lessonsCount}}</v-btn>
            </template>
            <template v-slot:item.rating="{ item }">
                <v-rating
                    readonly
                    :value="item.rating"
                    background-color="purple lighten-3"
                    color="purple"
                    dense
                    half-increments
                    size="16"
                    ></v-rating>
            </template>
            <template v-slot:top>
                <v-text-field
                    v-model="search"
                    class="mx-4"
                    ></v-text-field>
            </template>

        </v-data-table>
        <v-dialog
            v-model="statModal"
            max-width="1024"
            >
            <v-card scrollable>
                <v-card-text>
                    <uprogress v-if="userId" ref="uprogress" :groupCourseId="groupCourseId" :userId="userId"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
    import uprogress from '../../profile/progress'
    export default {
        name: "index",
        data: () => ({
                userId:false,
                statModal: false,
                headers: [
                    {text: 'Аватар', value: 'avatar'},
                    {text: 'ФИО', value: 'last_name'},
                    {text: 'Уроков пройдено', value: 'count'},
                    {text: 'Рейтинг', value: 'rating'}
                ],
                search: '',
                users: [],
                lessonsCount: 0
            }),
        props: {
            groupCourseId: Number,
        },
        components: {
            uprogress
        },
        methods: {
            fetchData() {
                this.$http.get('progress/course-progress?id=' + this.groupCourseId).then(response => {
                    this.users = response.data.users
                    this.lessonsCount = response.data.lessonsCount
                })
            }
        },
        mounted() {
            this.fetchData()
        }
    };
</script>