var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('h3',[_vm._v("Журнал инструктажей")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"item-key":"id","search":_vm.search,"no-data-text":"Результатов нет","no-results-text":"Результатов нет","footer-props":{'items-per-page-text':'Количество',
            'items-per-page-options':[5,10,20]
            }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
            var pageStart = ref.pageStart;
            var pageStop = ref.pageStop;
            var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" из "+_vm._s(itemsLength)+" ")]}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(parseInt(item.date),"D MMMM YYYY HH:mm"))+" ")]}},{key:"item.user",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.last_name)+" "+_vm._s(item.user.name)+" "+_vm._s(item.user.second_name)+" ")]}},{key:"item.position",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.position)+" ")]}},{key:"item.lesson",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.lesson.topic.name)+" - "+_vm._s(item.lesson.name)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }