<template>
    <!-- ----------------------------------------------------------------------------- -->
    <!-- Events -->
    <!-- ----------------------------------------------------------------------------- -->
    <div>
        <v-card>
            <v-app-bar
                flat
                >

                <v-toolbar-title>
                    Расписание
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn outlined
                       class="mr-4"
                       color="grey darken-2"  @click="masterDialog=true" >Мастер создания расписания</v-btn>
                <v-btn outlined
                       class="mr-4"
                       color="grey darken-2"  @click="tempEvent={};timetableEditDialog=true;" >Добавить занятие</v-btn>

                <v-btn @click="groupProgressModal=true" class="mr-4" color="primary"><v-icon>mdi-chart-bar-stacked</v-icon> Прогресс</v-btn>
                <v-btn @click="groupInstructionsDialog=true" dark color="orange"><v-icon>mdi-book-alert-outline</v-icon> Журнал ПОТ</v-btn>

            </v-app-bar>
            <v-card-text>

                <center>
                    <v-slide-group
                        multiple
                        show-arrows
                        >
                        <v-slide-item v-for='n in 12'  :key="n" > 
                            <v-btn depressed tile :input-value="n==month" @click="changeMonth(n)" active-class="primary" style='border: solid 1px; border-color: #ccc;'>
                                <span v-if='n==1'>Январь</span>
                                <span v-if='n==2'>Февраль</span>
                                <span v-if='n==3'>Март</span>
                                <span v-if='n==4'>Апрель</span>
                                <span v-if='n==5'>Май</span>
                                <span v-if='n==6'>Июнь</span>
                                <span v-if='n==7'>Июль</span>
                                <span v-if='n==8'>Август</span>
                                <span v-if='n==9'>Сентябрь</span>
                                <span v-if='n==10'>Октябрь</span>
                                <span v-if='n==11'>Ноябрь</span>
                                <span v-if='n==12'>Декабрь</span>
                            </v-btn>
                        </v-slide-item>
                    </v-slide-group>
                </center>
            </v-card-text>
            <center v-if="timetable.length==0"><h1 class="pa-7">Занятий нет</h1></center>
            <div v-for="(event,index) in timetable" :key="event.id" >
                <div  v-if="(event.lesson && event.lesson.topic) || event.type=='PTZ' || event.type=='PTU'">
                    <p class="line" v-if="index>0 && $moment.unix(parseInt(timetable[index-1].startTime)).format('D')!=$moment.unix(parseInt(timetable[index].startTime)).format('D')"><span>{{ parseInt(event.startTime) | moment("D, dddd") }}</span></p>
                    <p class="line"  v-else-if="index==0"><span>{{ parseInt(event.startTime) | moment("D, dddd") }}</span></p>
                    <v-list-item >
                        
                        <v-list-item-action>
                            {{ parseInt(event.startTime) | moment("HH:mm") }} - {{ parseInt(event.endTime) | moment("HH:mm") }}
                        </v-list-item-action>
                        <v-list-item-content v-if='!event.type'>
                            <v-list-item-title style='white-space: unset;'>{{event.lesson.topic.name}} {{event.lesson.name}}</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-chip small  v-if="event.lesson.type=='theory'">Теоретическое занятие</v-chip>
                                <v-chip small  color="primary"  v-if="event.lesson.type=='practice'">Практическое занятие</v-chip>
                                <v-chip small color="warning"  v-if="event.lesson.type=='self'">Самостоятельная подготовка</v-chip>
                                <v-chip  small  color="error"  v-if="event.lesson.type=='control'">Контроль</v-chip>
                                <v-chip  small class='mx-3' outlined>Часов: {{event.lesson.hours}}</v-chip>
                            </v-list-item-subtitle>

                        </v-list-item-content>
                        <v-list-item-content v-else>
                            <v-list-item-title style='white-space: unset;' v-if="event.type=='PTZ'"><h2>Пожарно-тактическое занятие</h2></v-list-item-title>  
                            <v-list-item-title style='white-space: unset;' v-if="event.type=='PTU'"><h2>Пожарно-тактическое учение</h2></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-btn fab small v-if="event.type==null && event.lesson.instruction" text-color='white' color="orange"><v-icon>mdi-book-alert-outline</v-icon></v-btn>
                            <v-btn fab small text-color='white' v-if="event.type==null" class='mx-2' @click="tempEvent=event; progressDialog=true"><v-icon>mdi-account</v-icon></v-btn>
                            <v-btn fab small color='primary' @click='tempEvent=event;timetableEditDialog=true' text-color='white' class='mx-2'><v-icon>mdi-pencil</v-icon></v-btn>
                            <v-btn fab small color='error' text-color='white' @click='deleteModal=true; tempEvent=event'><v-icon>mdi-delete</v-icon></v-btn>

                        </v-list-item-icon>
                    </v-list-item>
                </div>

            </div>
        </v-card>

        <v-dialog v-model="timetableEditDialog" persistent max-width="670">
            <v-card>
                <v-card-title class="headline">Добавить занятие</v-card-title>
                <v-card-text><lessonEdit @close='timetableEditDialog=false' @elementAdd="elementAdd" :groupCourseId='$route.params.id' :lesson.sync='tempEvent'></lessonEdit></v-card-text>


            </v-card>
        </v-dialog>
        <v-dialog v-model="masterDialog" scrollable max-width="1024">
            <master :groupCourseId="$route.params.id" />
        </v-dialog>
        <v-dialog v-model="progressDialog" scrollable max-width="1024">
            <v-card>
                <v-card-text>
                    <usersProgress v-if="tempEvent" :timetable="tempEvent" />
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="groupProgressModal" scrollable max-width="1024">
            <v-card>
                <v-card-text>
                    <gprogress :groupCourseId="$route.params.id"></gprogress>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="groupInstructionsDialog" scrollable max-width="1024">
            <v-card>
                <v-card-text>
                    <instructions :groupCourseId="$route.params.id"></instructions>
                </v-card-text>
            </v-card>
        </v-dialog>
                    <v-dialog v-model="deleteModal" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Вы уверены?</v-card-title>
                    <v-card-text>После удаления восстановление элемента расписания невозможно.</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text @click="deleteModal = false">Отменить</v-btn>
                        <v-btn color="error" text @click="elementDelete()">Удалить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </div>
</template>
<style>
    .line {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        position: relative;
    }
    .line span {
        background-color: #ffffff;
        position: relative;
        z-index: 1;
        padding: 0px 25px;
    }
    .line:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #262626;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        z-index: 0;
    }
</style>
<script>
    import lessonEdit from './timetable/lessonEdit'
    import master from './timetable/master'
    import usersProgress from './timetable/usersProgress'
    import instructions from './timetable/instructions'
    import gprogress from './timetable/progress'
    export default {
        data: () => ({
                month: 1,
                year: null,
                timetableEditDialog: false,
                timetable: [],
                masterDialog: false,
                tempLesson: {},
                tempEvent: {},
                progressDialog: false,
                groupProgressModal: false,
                groupInstructionsDialog: false,
                deleteModal:false
            }),
        components: {
            master,
            lessonEdit,
            usersProgress,
            instructions,
            gprogress
        },
        created() {
            this.fetchData()
        },
        methods: {
            elementDelete(){
                this.$http.get('calendar/delete?id=' + this.tempEvent.id).then(() => {
                    this.fetchData()
                    this.deleteModal=false
                })
            },
            elementAdd() {
                this.fetchData()
                this.timetableEditDialog = false
            },
            changeMonth(n) {
                this.month = n
                this.fetchData()
            },
            fetchData() {
                this.$http.get('calendar/timetable?id=' + this.$route.params.id + '&month=' + this.month + '&year=' + this.year).then(response => {
                    this.timetable = response.data.timetable
                })
            }
        },
    }

</script>