<template>
    <!-- ----------------------------------------------------------------------------- -->
    <!-- Events -->
    <!-- ----------------------------------------------------------------------------- -->

        <v-card flat>
            
            <v-card-title class="headline">Мастер создания расписания
            
            
            </v-card-title>
            <v-divider/>
            <v-card-subtitle class='pa-0'>
                            <v-stepper v-model="step" flat>
    <v-stepper-header>
      <v-stepper-step editable
        step="1"
      >
        Порядок занятий
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        step="2"
      >
        Даты
      </v-stepper-step>

    </v-stepper-header>
                </v-stepper>
                </v-card-subtitle>
            
            <v-divider/>
            <v-card-text class='pa-0'>

                <v-stepper v-model="step" flat>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                <v-list>

                    <draggable v-model="lessons" @end="setLessonsOrder()">
     
                        <v-list-item
                            v-for="(element, index) in lessons" 
                            :key="element.id"
                            
                            v-bind:class="[lessons[index].exclude ? 'error' : '']"
                            >
                            <v-list-item-avatar>
                                <v-icon small>{{index+1}}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                {{element.topic}} - {{element.name}} (Часов: {{element.hours}})
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="$set(lessons[index],'exclude',true)" v-if="lessons[index].exclude!=true"><v-icon>mdi-plus</v-icon></v-btn>
                                <v-btn icon @click="$set(lessons[index],'exclude',false)" v-else><v-icon>mdi-minus</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>

                    </draggable>

                </v-list>
                            

                            </v-stepper-content>
                        <v-stepper-content step="2"><v-row>
                                <v-col cols="auto">
                                    <h3>Исключить даты</h3>
                        <v-date-picker
        v-model="dates"
        multiple
        locale="ru-RU"
      ></v-date-picker>
                                </v-col>
                                <v-col>
                            <v-menu
        v-model="startMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            label="Дата первого занятия"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            locale="ru-RU"
          v-model="startDate"
          @input="startMenu = false"
        ></v-date-picker>
      </v-menu>
                                    <v-text-field
            v-model="every"
            type="number"
            hint="Расставлять занятия через заданное количество дней"
            label="Интервал между занятиями"
          ></v-text-field>
                                                                        <v-text-field
            v-model="hours"
            type="number"
            hint="Сколько часов отводить под занятия каждый день"
            label="Часов в день"
          ></v-text-field>
                                    </v-col>
                                <v-col>
                                    <h3>Время начала занятий</h3>
                                      <v-time-picker
      v-model="startTime"
      class="mt-4"
      format="24hr"
      scrollable

    ></v-time-picker>
                                </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                        <v-btn text v-if="step==2" @click="step=1">
          Назад
        </v-btn>
                        <v-btn v-if="step==1"
          color="primary"
          @click="step++"
        >
          Далее
        </v-btn>
        <v-btn v-if="step==2"
          color="primary"
          @click="create()"
        >
          Создать
        </v-btn>

            </v-card-actions>
        </v-card>

</template>

<script>
    import draggable from 'vuedraggable'
    export default {
        components: {
            draggable,
        },
        props: {
            groupCourseId: Object
        },
        data: () => ({
            step:1,
            dates:[],
            every:1,
            order:[],
            hours:1,
            startDate:null,
            startTime:null,
            startMenu:false,
                lessons: []
            }),
        mounted() {
            this.fetchData()
        },
        methods: {
            
            create(){
                this.$http.post('calendar/master-create?groupCourseId=' + this.groupCourseId,{
                    dates:this.dates,
                    order:this.order,
                    offset: new Date().getTimezoneOffset(),
                    every:this.every,
                    hours:this.hours,
                    startDate:this.startDate,
                    startTime:this.startTime
                }).then(response => {
                    console.log(response.data)
                })
            },
            getLessonsOrder() {
                var order = [];
                for (var s_index in this.lessons)
                {
                    if(!this.lessons[s_index].exclude){
                        //order[s_index][p_index]=this.sections[s_index].papers[p_index].id
                        order.push(this.lessons[s_index].id)
                    }
                }

                return order;
            },
            setLessonsOrder() {
                this.order = this.getLessonsOrder()
                console.log(this.order)
            },
            fetchData() {
                this.$http.get('calendar/get-lessons?groupCourseId=' + this.groupCourseId).then(response => {
                    this.lessons = response.data
                    this.setLessonsOrder()
                })
            }
        },
    }

</script>